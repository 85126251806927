<template>
  <q-form ref="editForm">
      <c-card title="아차사고 기본정보" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="아차사고번호" :value="nearAccident.iimNearAccidentNo"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="아차사고명" :value="nearAccident.iimNearAccidentName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="아차사고종류" :value="nearAccident.iimNearKindName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생일시" :value="nearAccident.occurrenceDt"></c-label-text>
          </div>
        </template>
      </c-card>
      <c-card title="계획 및 담당자의견" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <c-appr-btn 
              v-if="!isApprDisabled"
              ref="appr-btn"
              name="danger-journal-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="saveDataAppr"
              @callbackApprAction="approvalCallback"
              @requestAfterAction="getDetail"
            />
            <c-btn
              v-if="editable&&!disabled"
              :url="updateOpinionUrl"
              :isSubmit="isSaveOpin"
              :param="nearAccident"
              mappingType="PUT"
              label="계획/의견저장"
              icon="save"
              @beforeAction="saveNearAccident"
              @btnCallback="saveNearAccidentCallback"
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="6"
              label="단기계획"
              name="shortPlan"
              v-model="nearAccident.shortPlan">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="6"
              label="중장기 계획"
              name="longPlan"
              v-model="nearAccident.longPlan">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="6"
              label="담당자 의견"
              name="managerOpinion"
              v-model="nearAccident.managerOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-table
        ref="tableImpr"
        title="원인 및 재발방지 목록"
        :columns="gridImpr.columns"
        :data="nearAccident.preventions"
        selection="multiple"
        rowKey="iimNearCausesId"
        :usePaging="false"
        :isExcelDown="false"
        :autoFullHeight="true"
        :filtering="false"
        :columnSetting="false"
        :editable="editable && !disabled"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable && !disabled" 
              :showLoading="false"  
              label="추가" 
              icon="add" 
              @btnClicked="add" />
            <c-btn
              v-show="editable && !disabled && nearAccident.preventions.length > 0"
              :url="updateCauseUrl"
              :isSubmit="isSaveCause"
              :param="nearAccident.preventions"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveNearImpr"
              @btnCallback="saveNearImprCallback" 
            />
            <c-btn 
              v-if="editable && !disabled && nearAccident.preventions.length > 0"
              :showLoading="false" 
              label="삭제" 
              icon="remove" 
              @btnClicked="removeImpr" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="editable&&!disabled"
              :isImmShow="true"
              :requestContentsCols="requestContentsCols"
              tableKey="iimNearCausesId"
              ibmTaskTypeCd="ITT0000045"
              ibmTaskUnderTypeCd="ITTU000070"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
  </q-form>
</template>
<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'nearCausePrevention',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
    nearAccident: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        plantCd: '',
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimStatusNearCd: '',
        reportDeptCd: '',
        reportUserId: '',
        reportDt: '',
        iimNearKindCds: '',
        iimGradeCd: null,
        discovererId: '',
        occurrenceDt: '',
        occurrenceLocation: '',
        reviewDeptCd: '',
        weatherCd: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        iimNearDiscernCds: null,
        iimNearFunctionCds: null,
        iimNearFeelCds: null,
        iimNearEquipCds: null,
        iimNearManageCds: null,
        processCd: '',
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        auditScore: null,
        auditOpinion: '',
        bounty: null,
        approvalRequestStatusCd: '', // 결재관련 결재상태 요청승인
        sysApprovalRequestRequestId: '', // 결재관련 결재요청코드 요청승인
        approvalReviewStatusCd: '', // 결재관련 결재상태 검토승인
        sysApprovalReviewRequestId: '', // 결재관련 결재요청코드 검토승인
        approvalAuditStatusCd: '', // 결재관련 결재상태 심사승인
        sysApprovalAuditRequestId: '', // 결재관련 결재요청코드 심사승인
        preventions: [],
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    isOld: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'iimNearCauseTypeCd',
            field: 'iimNearCauseTypeCd',
            label: '원인구분',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            codeGroupCd: 'IIM_NEAR_CAUSE_DIVISION_CD',  //IIM_CAUSE_DIVISION_CD
            sortable: false,
          },
          {
            required: true,
            name: 'iimNearAccidentCause',
            field: 'iimNearAccidentCause',
            label: '사고원인',
            align: 'left',
            type: 'text',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '원인 및 재발방지 진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:200px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'iimPreventionMeasures',
                field: 'iimPreventionMeasures',
                label: '원인 및 재발방지 대책',
                align: 'left',
                style: 'width:400px',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
      },
      editable: true,
      isSaveOpin: false,
      isSaveCause: false,
      isApproval: false,
      updateOpinionUrl: '',
      updateCauseUrl: '',
      deleteCauseUrl: '',
    };
  },
  computed: {
    disabled() {
      return !this.editable 
        || (this.isOld 
          && (this.nearAccident.iimStatusNearCd !== 'ISNC000002'
            || this.nearAccident.approvalReviewStatusCd === 'ASC0000001'
            || this.nearAccident.reviewDeptCd !== this.$store.getters.user.deptCd
          )
        )
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['iimNearCauseTypeName', 'iimNearAccidentCause'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.nearAccident.sysApprovalReviewRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.nearAccident.approvalReviewStatusCd, 
        apprEditable: !this.disabled && this.isOld, // 결재버튼 활성화 기준
        param: this.nearAccident, // 결재 param
        approvalUrl: transactionConfig.sop.iim.accident.near.update.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000023', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          iimNearAccidentId: this.popupParam.iimNearAccidentId,
          iimStatusNearCd: this.nearAccident.iimStatusNearCd,
          isApprDisabled: true,
        },
        approvalRequestName: '[아차사고 검토승인] ' + this.nearAccident.iimNearAccidentName, // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.iimNearAccidentId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },    
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.updateOpinionUrl = transactionConfig.sop.iim.accident.near.update.url;
      this.updateCauseUrl = transactionConfig.sop.iim.accident.near.prevention.update.cause.url;
      this.deleteCauseUrl = transactionConfig.sop.iim.accident.near.prevention.delete.url;
      // code setting
      // list setting
    },
    getDetail() {
      this.$emit('getDetail')
    },
    imprChange() {    
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    saveNearAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId

              this.isSaveOpin = !this.isSaveOpin;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveNearAccidentCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail', 'planOpinion')
    },
    saveNearImpr() {
      if (this.$comm.validTable(this.gridImpr.columns, this.nearAccident.preventions)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveCause = !this.isSaveCause;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveNearImprCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail', 'prevention')
    },
    add() {
      if (!this.nearAccident.preventions) this.nearAccident.preventions = [];
      this.nearAccident.preventions.splice(0, 0, {
        iimNearAccidentId: this.popupParam.iimNearAccidentId,
        iimNearCausesId: uid(),    // uuid와 동일한것
        iimNearCauseTypeCd: null,
        iimNearCauseTypeName: '',
        iimImprovingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '',
        regUserId: this.$store.getters.user.userId, 
        editFlag: 'C',
      })
    },
    removeImpr() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {   //삭제하려고 선택한 값이 하나도 없을 경우
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteCauseUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                // 내가 선택한 제외 목록이 실제로 없애야 함으로
                this.nearAccident.preventions = this.$_.reject(this.nearAccident.preventions, item)
              })
              this.$refs['tableImpr'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        // 결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?
        message: 'MSG0000430',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.nearAccident.regUserId = this.$store.getters.user.userId
          this.nearAccident.chgUserId = this.$store.getters.user.userId

          this.isApproval = !this.isApproval
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};          
</script>